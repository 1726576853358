.conditions-page {
    counter-reset: section;
}

.conditions-page h4 {
    border-bottom: 2px solid;
    margin-block: 1rem;
    margin-top: 2rem;
    padding-bottom: 1rem;
}

.conditions-page h4:before {
    counter-increment: section;
    content: counter(section) ". ";
}

.conditions-page .back-button {
    border: 2px solid;
    background: transparent;
    font-weight: bold;
    border-radius: 50%;
    line-height: 0;
    font-size: 24px;
    padding: 17px 10px 15px;
}

.conditions-page .note {
    color: red;
}