.login-page {
  max-width: 414px;
  margin: 0 auto;
}

.login-header {
  margin-bottom: 20px;
}

.login-logo,
.login-title {
  height: 40px;
  margin-top: 20px;
  margin-bottom: 0;
}

.login-logo {
  width: 40px;
  margin-left: 20px;
}

.login-title {
  line-height: 40px;
  margin-right: 20px;
}

.login-button {
  cursor: pointer;
  border: 3px solid var(--theme-color-white);
  line-height: 44px;
  padding: 0 12px;
  border-radius: 25px;
  font-weight: 500;
  text-align: center;
  color: var(--theme-color-white);
  width: 100%;
  max-width: 240px;
  font-size: 21px;
  background-color: var(--theme-color-brandblue);
  text-transform: uppercase;
  position: absolute;
  bottom: 76px;
  left: 50%;
  transform: translateX(-50%);
}

.register-section {
  background: var(--theme-color-secondaryblue);
  color: var(--theme-color-white);
  padding: 20px;
  min-height: 150px;
}

.register-section a {
  color: var(--theme-color-white);
  text-decoration: underline;
}
